<template>
  <v-card flat>
    <v-card-text
      class="pb-0"
    >
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center text-uppercase">
                  Division
                </th>
                <th class="text-right text-uppercase">
                  Banned user
                </th>
                <th class="text-center text-uppercase">
                  Duration
                </th>
                <th class="text-left text-uppercase">
                  Reason
                </th>
                <th class="text-center text-uppercase">
                  Ban start
                </th>
                <th class="text-center text-uppercase">
                  Ban end
                </th>
                <th class="text-center text-uppercase">
                  Banned by
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ban in $store.getters['faceit/GetBans']"
                :key="ban.id"
              >
                <td class="text-center">
                  <div :class="ban.division == 'Division 1' ? 'division1-badge' : 'division2-badge'">
                    {{ ban.division }}
                  </div>
                </td>
                <td class="text-right">
                  {{ ban.nickname }}
                </td>
                <td class="text-center">
                  <div class="banned-badge">
                    {{ getDateDiff(ban.banStart, ban.banEnd) }}h
                  </div>
                </td>
                <td class="text-left">
                  {{ ban.reason }}
                </td>
                <td class="text-center">
                  {{ formatDate(ban.banStart) }}
                </td>
                <td class="text-center">
                  {{ formatDate(ban.banEnd) }}
                </td>
                <td class="text-center">
                  {{ ban.creatorNickname }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {}
</script>
